<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3">
                    <Create @submit="getDataTable()" ref="create"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <!-- <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" /> -->
                                <h4>User</h4>
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="level_name" header="Level">
                        <template #body="slotProps">
                            {{slotProps.data.level_name}}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="name_" header="Name">
                        <template #body="slotProps">
                            {{slotProps.data.name_}}
                        </template>
                    </Column>
                    <Column field="phone" header="Phone">
                        <template #body="slotProps">
                            {{slotProps.data.phone}}
                        </template>
                    </Column>
                    <Column field="email" header="Email">
                        <template #body="slotProps">
                            {{slotProps.data.email}}
                        </template>
                    </Column>
                    <Column field="spv_name" header="Supervisor Name">
                        <template #body="slotProps">
                            {{slotProps.data.spv_name}}
                        </template>
                    </Column>
                    <Column field="spv_username" header="Supervisor Username">
                        <template #body="slotProps">
                            {{slotProps.data.spv_username}}
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)"/>
                            <Button icon="pi pi-eye" class="p-button-rounded p-button-secondary" @click="detailItem(slotProps.data)"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

                <Edit @submit="getDataTable()" :item="item" ref="edit"/>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../../utils/helpers';
import Create from '../../../components/data/user/Create.vue';
import Edit from '../../../components/data/user/Edit.vue';
import Error from '../../../components/Error.vue';

export default {
    components: {
		'Create': Create,
		'Edit': Edit,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            // detail,edit
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DETAIL
		detailItem(item) {
			this.item = item;
            this.$router.replace({ name: "data-user-detail", params: {id:this.item.userid} })
		},
        // EDIT
		editItem(item) {
			this.item = item;
            
            this.$refs.edit.editItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/user',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    // "userid" : this.$refs.filter.filters.userid,
                    // "tipe" : this.$refs.filter.filters.tipe,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            //   this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/user/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Data User Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>
