<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>Username</label>
            <InputText v-model="item.username" required="true" autofocus :class="{ 'p-invalid': errorEdit.username }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.username" >{{ errorEdit.username[0] }}</small >
        </div>
         <div class="field">
            <label>Name</label>
            <InputText v-model="item.name_" required="true" autofocus :class="{ 'p-invalid': errorEdit.name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.name" >{{ errorEdit.name[0] }}</small >
        </div>
         <div class="field">
            <label>Phone</label>
            <InputText v-model="item.phone" required="true" autofocus :class="{ 'p-invalid': errorEdit.phone }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.phone" >{{ errorEdit.phone[0] }}</small >
        </div>
         <div class="field">
            <label>Email</label>
            <InputText type="email" v-model="item.email" required="true" autofocus :class="{ 'p-invalid': errorEdit.email }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.email" >{{ errorEdit.email[0] }}</small >
        </div>
         <div class="field">
            <label>Password</label>
            <Password :feedback="false" v-model="password" placeholder="Password" :toggleMask="true" :class="{'p-invalid': errorEdit.password}"></Password>
            <small>*Silahkan isi jika ingin mengganti password</small>
            <br>
            <small class="p-invalid" style="color: red" v-if="errorEdit.password" >{{ errorEdit.password[0] }}</small >
        </div>
        <div class="field">
            <label>Level</label>
            <Dropdown dataKey="label" 
                    ref="dlvl" v-model="item.level_id" 
                    :loading="loadingDropdownPosition" 
                    :options="dataDropdownPosition" 
                    :class="{ 'p-invalid': errorEdit.position_id }" 
                    optionLabel="userapp_pos_name" optionValue="userapp_pos_id" 
                    placeholder="Pilih Level" :filter="true" 
                    :showClear="true" @filter="searchDropdownPosition($event,'edit')"
                    @change="changeSpv()"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.position_id" >{{ errorEdit.position_id[0] }}</small>
        </div>
        <div class="field">
            <label>Supervisor</label>
            <Dropdown dataKey="mcu_id" 
                    ref="dspv" v-model="item.spv_id" 
                    :loading="loadingDropdownSpv" 
                    :options="dataDropdownSpv" 
                    :class="{ 'p-invalid': errorEdit.spv_id }" 
                    optionLabel="name" optionValue="mcu_id" 
                    placeholder="Pilih SPV" :filter="true" 
                    :showClear="true" @filter="searchDropdownSpv($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.spv_id" >{{ errorEdit.spv_id[0] }}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownPosition: false,
            loadingDropdownSpv: false,

            // dataDropdown
            dataDropdownPosition: null,
            dataDropdownSpv: null,

            // edit
            editItemDialog: false,

            password: null,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.password = null;
                        this.searchDropdownPosition('', 'edit', this.item.level_name);
                        this.searchDropdownSpv('', 'edit', this.item.spv_name);
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        position_id_spv(userapp_pos_id) {
            if (!this.dataDropdownPosition) return null;
            
            const filteredPosition = this.dataDropdownPosition.find(position => position.userapp_pos_id === userapp_pos_id);
            return filteredPosition ? filteredPosition.userapp_spv_pos_id : null;
        },
        // DROPDOWN
        searchDropdownPosition(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dlvl.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownPosition = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/userapp-position',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownPosition = res.data.data;
                        this.loadingDropdownPosition = false;
                    }else if(purpose == null){
                        this.dataDropdownPosition = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeSpv() {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.spv_id = null;
            this.dataDropdownSpv = null;
            this.searchDropdownSpv('');
        },
        searchDropdownSpv(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.item.level_id){
                    if(valueEdit){
                        this.$refs.dspv.filterValue = valueEdit;
                    }

                    if(purpose == "edit"){
                        this.loadingDropdownSpv = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/filter-user4',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "position_id" : this.position_id_spv(this.item.level_id)
                        }
                    })
                    .then(res => {

                        if(purpose == "edit"){
                            this.dataDropdownSpv = res.data.data;
                            this.loadingDropdownSpv = false;
                        }else if(purpose == null){
                            this.dataDropdownSpv = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/user/update',
                data: {
                    "userid" : this.item.userid,
                    "username": this.item.username,
                    "password": this.password,
                    "name": this.item.name_,
                    "phone": this.item.phone,
                    "email": this.item.email,
                    "position_id": this.item.level_id,
                    "spv_id": this.item.spv_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
		},
    }
}
</script>