<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Username</label>
                <InputText v-model="forms.username" required="true" autofocus :class="{ 'p-invalid': errorAdd.username }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.username" >{{ errorAdd.username[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Password</label>
                <Password :feedback="false" v-model="forms.password" placeholder="Password" :toggleMask="true" :class="{'p-invalid': errorAdd.password}"></Password>
                <small class="p-invalid" style="color: red" v-if="errorAdd.password" >{{ errorAdd.password[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Name</label>
                <InputText v-model="forms.name" required="true" autofocus :class="{ 'p-invalid': errorAdd.name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.name" >{{ errorAdd.name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Phone</label>
                <InputText v-model="forms.phone" required="true" autofocus :class="{ 'p-invalid': errorAdd.phone }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.phone" >{{ errorAdd.phone[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Email</label>
                <InputText type="email" v-model="forms.email" required="true" autofocus :class="{ 'p-invalid': errorAdd.email }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.email" >{{ errorAdd.email[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Level</label>
                    <Dropdown dataKey="userapp_pos_id" v-model="forms.position_id" :loading="loadingDropdownPosition"
                        :options="dataDropdownPosition" :class="{ 'p-invalid': errorAdd.position_id }"
                        optionLabel="userapp_pos_name" placeholder="Pilih Level"
                        :filter="true" :showClear="true" @filter="searchDropdownPosition($event, 'add')" @change="changeSpv()"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.position_id">{{
                        errorAdd.position_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Supervisor</label>
                    <Dropdown dataKey="mcu_id" v-model="forms.mcu_id" :loading="loadingDropdownSpv"
                        :options="dataDropdownSpv" :class="{ 'p-invalid': errorAdd.spv_id }"
                        optionLabel="name" optionValue="mcu_id" placeholder="Pililh SPV"
                        :filter="true" :showClear="true" @filter="searchDropdownSpv($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.spv_id">{{
                        errorAdd.spv_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownPosition: false,
            loadingDropdownSpv: false,

            // dataDropdown
            dataDropdownPosition: null,
            dataDropdownSpv: null,

            // addNew
            forms: {
                username: null,
                password: null,
                name: null,
                phone: null,
                email: null,
                position_id: null,
                mcu_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownPosition('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownPosition(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dtt.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownPosition = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/userapp-position',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownPosition = res.data.data;
                        this.loadingDropdownPosition = false;
                    }else if(purpose == null){
                        this.dataDropdownPosition = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeSpv() {
            this.forms.mcu_id = null;
            this.dataDropdownSpv = null;
            this.searchDropdownSpv('');
        },
        searchDropdownSpv(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.forms.position_id){
                    
                    if(valueEdit){
                        this.$refs.dterritory.filterValue = valueEdit;
                    }

                    if(purpose == "add"){
                        this.loadingDropdownSpv = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/filter-user4',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "position_id" : this.forms.position_id.userapp_spv_pos_id
                        }
                    })
                    .then(res => {

                        if(purpose == "add"){
                            this.dataDropdownSpv = res.data.data;
                            this.loadingDropdownSpv = false;
                        }else if(purpose == null){
                            this.dataDropdownSpv = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/user/create',
                data: {
                    "username": this.forms.username,
                    "password": this.forms.password,
                    "name": this.forms.name,
                    "phone": this.forms.phone,
                    "email": this.forms.email,
                    "position_id": this.forms.position_id.userapp_pos_id,
                    "spv_id": this.forms.mcu_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.username = null;
            this.forms.password = null;
            this.forms.name = null;
            this.forms.phone = null;
            this.forms.email = null;
            this.forms.position_id = null;
            this.forms.mcu_id = null;

            this.dataDropdownSpv = null;
        },
    }
}
</script>